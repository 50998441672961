import React from "react"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import SEO from "../components/seo"

import ABOUT1 from "../images/about/About Image 1.jpg"
import ABOUT2 from "../images/about/About Image 2.jpg"
import ABOUT3 from "../images/about/About Image 3.jpg"
import ABOUT4 from "../images/about/About Image 4.jpg"
import ABOUT5 from "../images/about/About Image 5.jpg"
import ABOUT6 from "../images/about/About Image 6.jpg"
import ABOUT7 from "../images/about/About Image 7.jpg"

import AboutSidebar from "../components/layout/about-sidebar"

const AboutPage = () => (
  <Layout className="main inner-page-with-banner">
    <SEO
      title={`About Us`}
      description={`From small town woods to a world of opportunity.`}
    />

    {/* CONTENT GOES HERE */}
    <InnerWrapper>
      <div className="page-hero"></div>

      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/about">About</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="generalcontent">
        <Col md={3}>
          <AboutSidebar></AboutSidebar>
        </Col>
        <Col md={6}>
          <h1 className="generalheader">About Walpole</h1>
          <h4>
            <strong>From small town woods to a world of opportunity.</strong>
          </h4>

          <div className="mt-5">
            <strong>1933</strong>
            <br />
            <strong>Founded in Walpole, MA</strong>
            <p>
              If an inspirational location can stimulate great ideas, then the
              dense Walpole woods are to be revered for posterity. It was here
              amidst the cedar trees that P.R. Allen made the decision to start
              a fence company and staff it with area residents. This new
              employment opportunity was a life-altering decision for many, as
              the year was 1933 and America was in the midst of The Great
              Depression.
            </p>
            <strong>1933-1950</strong>
            <p>
              With the addition of two company-owned mills in Maine, Walpole
              dramatically increased its production capabilities. The lumber of
              choice was Northern White Cedar, noted for its durability, decay
              resistance, and handsome good looks. At the same time, Walpole
              craftsmen were building impressive custom machinery to help handle
              increasing demand, including advanced sawing and sanding tools.
            </p>
            <p>
              In 1938, the famous architect Royal Barry Wills designed modular
              buildings from fallen lumber after the hurricane of ’38. Offered
              in various sizes, with custom choices in doors, windows, cupolas,
              and other features, these meticulously handcrafted small buildings
              became much sought after as garden retreats, tool sheds, guest
              houses, poolside cabanas, offices, art studios, and more.
            </p>
            <p>
              During WW2, many women rolled their sleeves up and assumed
              important roles at Walpole, from driving company trucks to
              hand-peeling timbers for a new line of rustic furniture.
            </p>
            <strong>1951-1970</strong>
            <p>
              Walpole developed a unique factory pre-staining system that coats
              wood more evenly than any other process, thereby eliminating
              unsightly paint mess onsite.
            </p>
            <p>
              Products crafted in wood by Walpole were now running the gamut
              from standard and custom fence styles and pergolas, to arbors,
              trellis, gates, railing, mail and pillar posts, outdoor
            </p>
            <p>furniture, architectural detailing, and much more.</p>
            <strong>1971-2000</strong>
            <p>
              Through careful planning, decades of experience, a skilled
              workforce, and by controlling
            </p>
            <p>
              the entire process from forest to finish, Walpole consistently met
              the promise of exception- al quality, impressive style, and
              customer-pleasing selection.
            </p>
            <p>
              The company’s reputation was so highly regarded that in 1974
              Walpole Woodworkers appeared on the premier episode of PBS TV’s
              This Old House. The relationship is ongoing.
            </p>
            <strong>2001-2022</strong>
            <p>
              Instrumental to the healthy growth of Walpole Woodworkers were the
              knowledgeable staff at retail showrooms located throughout the
              east coast, the national sales team’s expertise when reaching out
              to customers nationwide, and the incomparable style and design
              choices available on the company’s website.
            </p>
            <p>
              Anticipating the increasing demand from homeowners, businesses,
              builders, and architects for low maintenance outdoor products,
              Walpole allocated significant resources to studying advanced
              wood-alternative materials.
            </p>
            <p>
              In 2006 Walpole chose AZEK cellular vinyl as the ideal option to
              natural wood in the crafting of fence, pergolas, arbors, and
              other products. Walpole became the nation’s leader in fabricating,
              reinforcing, and finishing outdoor structures in AZEK. A
              significant investment in more than 50 proprietary dies allowed
              the company to create seamless beams, joists, pickets, and caps to
              accommodate the specific needs of homeowners and businesses. Now
              that Walpole was no longer an exclusive woodworking company, a
              name that reflected these exciting changing times became
              appropriate. In 2015, the company was renamed Walpole Outdoors.
            </p>
            <strong>A proud past, an exciting tomorrow.</strong>
            <p>
              Today’s Walpole is a highly skilled, customer focused enterprise
              that makes smart use of the latest technology and materials. In
              anticipating changing styles and taste, Walpole offers more than
              100 color choices in Sherwin-Williams paint.
            </p>
            <p>
              Looking forward with confidence and commitment through attractive
              showrooms and a significant online presence, Walpole is reaching
              out to new homeowners and businesses, and meeting the upgrade
              needs of existing customers wherever their location.
            </p>
            <p>
              Handcrafted with the same skill sets honed over generations of
              woodworkers, Walpole fence and outdoor structures designed in AZEK
              enhance downtown condos and high rise roof decks, create urban
              curb appeal, beautify suburban back yards, and gracefully define
              rural vistas.
            </p>{" "}
            <p>
              On the commercial side, Walpole Outdoors continues to be
              top-of-mind when projects are considered for hospitals,
              universities, shopping malls, restaurants, and more.
            </p>
          </div>
        </Col>
        <Col md={3}>
          <Row>
            <img className="ml-4 " src={ABOUT1} />
          </Row>
          <Row>
            <img className="ml-4 mt-4" src={ABOUT2} />
          </Row>
          <Row>
            <img className="ml-4  mt-4" src={ABOUT3} />
          </Row>
          <Row>
            <img className="ml-4  mt-4" src={ABOUT4} />
          </Row>
          <Row>
            <img className="ml-4  mt-4" src={ABOUT5} />
          </Row>
          <Row>
            <img className="ml-4  mt-4" src={ABOUT6} />
          </Row>
          <Row>
            <img className="ml-4  mt-4" src={ABOUT7} />
          </Row>
        </Col>
      </Row>
    </InnerWrapper>
  </Layout>
)

export default AboutPage

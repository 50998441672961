import React from "react"
import { Link } from "gatsby"

const AboutSidebar = () => (
  <div className="sidebarcontainer">
    <ul className="sidebar-nav">
      <li>
        <Link to="/about">About Walpole</Link>
      </li>
      {/* <li>
        <Link to="/about/message-ceo">A Message from CEO</Link>
      </li> */}
      <li>
        <Link to="/about/our-locations">Our Locations</Link>
      </li>
      <li>
        <Link to="/about/privacy-policy">Legal Notices</Link>
      </li>

      <li>
        <Link to="/blog">Blog</Link>
      </li>

      <li>
        <Link to="/about/partnerships">Partnerships</Link>
      </li>
      <li>
        <Link to="/about/careers">Careers</Link>
      </li>
      <li>
        <Link to="/about/walpole-difference">The Walpole Difference</Link>
      </li>
    </ul>
  </div>
)
export default AboutSidebar
